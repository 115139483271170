import { useStaticQuery, graphql } from 'gatsby';

import { ReviewGraphQL } from '../types/review';

const getReviewsByProductId = (productId: string): ReviewGraphQL[] => {
  const {
    allReviews: { edges },
  } = useStaticQuery(graphql`
    query {
      allReviews(
        filter: { display: { eq: true } }
        sort: { fields: submittedOn, order: DESC }
      ) {
        edges {
          node {
            content
            display
            id
            media
            product {
              id
              name
            }
            rating
            submittedOn
            user {
              displayPic
              firstName
              lastName
            }
          }
        }
      }
    }
  `);

  return edges
    .filter(
      ({ node }: { node: { product: { id: string } } }) =>
        node.product.id === productId
    )
    .map(({ node }: { node: ReviewGraphQL }) => node);
};

export default function useGraphQLGetReviews(
  productId: string
): ReviewGraphQL[] {
  return getReviewsByProductId(productId);
}
